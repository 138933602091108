import {PaginatedResponse} from "@/types/types";
import {
  BuyFNFTOrder,
  BuyFNFTRequest,
  FractionalNFT,
  FNFTPrice,
  FNFTTableItem
} from "@/components/Fractions/types";
import {PerpToken, MarketStats} from "@/components/Perps/types";
import postData from "@/api/postRequest";

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8084"
    : process.env.NEXT_PUBLIC_BACKEND_API;

export const fetchFNFTList = async (): Promise<FNFTTableItem[]> => {
  const response = await fetch(`${BASE_URL}/api/fractional_nft`);
  return response.json();
}

export const fetchFNFT = async (address: string): Promise<PerpToken> => {
  const response = await fetch(`${BASE_URL}/api/fractional_nft/${address}`);
  return response.json();
}

export const fetchFractionData = async (address: string): Promise<PaginatedResponse<FractionalNFT>> => {
  const response = await fetch(`${BASE_URL}/api/fractional_nft/list/${address}`);
  return response.json();
}

export const fetchFractionPrices = async (address: string): Promise<FNFTPrice> => {
  const response = await fetch(`${BASE_URL}/api/fractional_nft/prices/${address}`);
  return response.json();
}

export const fetchFractionStats = async (address: string): Promise<MarketStats> => {
  const response = await fetch(`${BASE_URL}/api/fractional_nft/stats/${address}`);
  return response.json();
}

export const fetchFractionBuyOrder = async (request: BuyFNFTRequest): Promise<BuyFNFTOrder> => {
  return await postData(`${BASE_URL}/api/fractional_nft/buy`, request);
}
