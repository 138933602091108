import {useQuery} from "@tanstack/react-query";
import React from "react";
import {FractionTokenPrice} from "@/components/Perps/FractionTokenPrice";
import classNames from "classnames";
import {GoTriangleDown, GoTriangleUp} from "react-icons/go";
import {prettifyNumber} from "@/util/converters";
import {FNFTPrice} from "@/components/Fractions/types";
import {fetchFractionPrices, fetchFractionStats} from "@/api/fractionDataFetcher";
import {useScreen} from "@/util/useScreen";
import {MarketStats} from "@/components/Perps/types";


export type Props = {
  address: string;
}

type FNFTStatProps<T> = {
  label: string;
  children: (v: T) => any;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  data: T | undefined
}


function FNFTStat<T extends any>({label, children, isLoading, isError, isSuccess, data}: FNFTStatProps<T>) {
  return (
    <div className="flex flex-col gap-1 justify-end">
      <span className="text-sm text-neutral-content font-light text-right">{label}</span>
      { isLoading && <div className="min-w-[100px] min-h-[20px] rounded-md bg-slate-600 animate-pulse m-2"/> }
      { isError && <span className="text-sm text-put">Error</span> }
      { isSuccess && children(data!) }
    </div>
  )
}

export const FNFTStatsView = ({address}: Props) => {
  const isDesktop = useScreen('md');

  const priceQuery = useQuery<FNFTPrice>({
    queryKey: ['fnft_price_', address],
    queryFn: async () => fetchFractionPrices(address),
    gcTime: 20 * 1000,
    refetchInterval: 20 * 1000,
  });

  const statsQuery = useQuery<MarketStats>({
    queryKey: ['fnft_stats_', address],
    queryFn: async () => fetchFractionStats(address),
    gcTime: 20 * 1000,
    refetchInterval: 20 * 1000,
  });

  return (
    <div className="flex flex-row gap-2 lg:gap-12 justify-between w-full md:w-max lg:justify-evenly items-center">
      <FNFTStat<FNFTPrice>
        {...priceQuery}
        label="Floor Price"
        children={v =>
          <FractionTokenPrice
            className="text-2xl justify-end"
            iconSize={16}
            price={Number(v.floorPrice)}
            showAsFullNFT={false} />}
      />
      <FNFTStat<FNFTPrice>
        {...priceQuery}
        label="Top Bid"
        children={v =>
          <FractionTokenPrice
            className="text-2xl justify-end"
            iconSize={16}
            price={Number(v.sellPrice)}
            showAsFullNFT={false} />}
      />
      <FNFTStat<MarketStats>
        {...statsQuery}
        label="1h Change"
        children={v =>
          <div className={classNames("flex flex-row items-center text-2xl text-right", {
            "text-call": v.oneHourChange > 0,
            "text-neutral-content": v.oneHourChange === 0,
            "text-put": v.oneHourChange < 0,
          })}>
            {v.oneHourChange > 0 ? <GoTriangleUp size={20} /> : <GoTriangleDown size={20} />}{ Math.abs(v.oneHourChange).toFixed(2)}%
          </div>
        }
      />
      { isDesktop && <>
        <FNFTStat<MarketStats>
          {...statsQuery}
          label="1d Change"
          children={v =>
            <div className={classNames("flex flex-row items-center text-2xl text-right", {
              "text-call": v.oneDayChange > 0,
              "text-neutral-content": v.oneDayChange === 0,
              "text-put": v.oneDayChange < 0,
            })}>
            {v.oneDayChange > 0 ? <GoTriangleUp size={20} /> : <GoTriangleDown size={20} />}{ Math.abs(v.oneDayChange).toFixed(2)}%
            </div>
          }
        />
        <FNFTStat<MarketStats>
          {...statsQuery}
          label="1h Volume"
          children={v =>
            <div className="text-2xl text-right flex flex-row items-center justify-end">
              <span className="text-base">$</span>{prettifyNumber(v.oneHourVolumeUsd)}
            </div>
          }
        />
        <FNFTStat<MarketStats>
          {...statsQuery}
          label="1d Volume"
          children={v =>
            <div className="text-2xl text-right flex flex-row items-center justify-end">
              <span className="text-base">$</span>{prettifyNumber(v.oneDayVolumeUsd)}
            </div>
          }
        />
      </>}
    </div>
  )
}
