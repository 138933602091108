import React from "react";
import {FaEthereum} from "react-icons/fa6";
import {twMerge} from "tailwind-merge";
import {TokenType} from "@/components/Perps/types";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {isUsdToken} from "@/util/chainConstants";

export type Props = {
  price: number;
  showAsFullNFT?: boolean;
  iconSize?: number;
  exponentClassName?: string;
  className?: string | undefined;
  tokenAddress?: string;
  tokenType?: TokenType;
  showInUsd?: boolean;
}

const FLC = "0x102c776ddb30c754ded4fdcc77a19230a60d4e4f".toLowerCase();

export const withSubscript = (value: number) => {
  if (value > 0.001) {
    let maxFractionDigits;
    if (value > 1000) {
      maxFractionDigits = 1;
    } else if (value > 100) {
      maxFractionDigits = 2;
    } else if (value > 10) {
      maxFractionDigits = 3;
    } else if (value > 1) {
      maxFractionDigits = 4;
    } else {
      maxFractionDigits = 5;
    }
    return value.toLocaleString([], { maximumFractionDigits: maxFractionDigits });
  }

  let i = 0;
  while (value < 1000) {
    value *= 10;
    i++;
  }
  return <span>0.0<sub className="text-[10px]">{i - 4}</sub>{value.toFixed(0)}</span>
}

export const FractionTokenPrice = ({ price, showAsFullNFT = false, iconSize, exponentClassName, className, tokenAddress, tokenType, showInUsd }: Props) => {
  const {ethPrice} = useEthPrice();

  const isUSDToken = isUsdToken(tokenAddress);
  let isInUsd = isUSDToken || showInUsd || false;

  let value: string | React.ReactNode;

  if (isUSDToken) { // ETH / USD
    value = (1 / Number(price)).toLocaleString([], { maximumFractionDigits: 2 });
  } else if (isInUsd) { // Token price in USD
    if (!ethPrice) {
      value = 0;
    } else {
      const usdPrice = Number(price) * ethPrice;
      value = withSubscript(usdPrice);
    }
  } else {
    value = withSubscript(Number(price))
  }

  return (
    <span className={twMerge("flex flex-row items-center", className || '')}>
      {isInUsd ? "$" : <FaEthereum size={iconSize || ''} />}{value}
    </span>
  )
}