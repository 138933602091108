import {numberSort, Table, TableColumn} from "@/components/Table";
import {FNFTTableItem} from "@/components/Fractions/types";
import {useQuery} from "@tanstack/react-query";
import {fetchFNFTList} from "@/api/fractionDataFetcher";
import React from "react";
import {FractionTokenPrice} from "@/components/Perps/FractionTokenPrice";
import classNames from "classnames";
import {GoTriangleDown, GoTriangleUp} from "react-icons/go";
import {prettifyNumber} from "@/util/converters";
import {Token} from "@/components/Perps/types";

export const FNFTTable = ({onSelected}: {onSelected: (t: Token) => any}) => {

  const {data, isLoading, isError} = useQuery({
    queryKey: ["fnft_table"],
    queryFn: async () => await fetchFNFTList(),
    gcTime: 20 * 1000, // 20 seconds
    refetchInterval: 20 * 1000, // 20 seconds
  });

  const columns: TableColumn<FNFTTableItem>[] = [
    {
      id: "collection",
      label: "Collection",
      valueRenderer: d => <div className="flex flex-row gap-2 items-center">
        <img src={d.token.imageUrl} alt={d.token.name} className="w-8 h-8 rounded-full"/>
        <div>{d.token.name}</div>
      </div>,
      span: 2,
    },
    {
      id: "floor",
      label: "Floor Price",
      valueRenderer: d =>
        <FractionTokenPrice
          className="justify-end"
          iconSize={12}
          price={Number(d.price.floorPrice)}
          showAsFullNFT={false} />,
      align: "right",
      sort: numberSort(a => a.price.floorPrice)
    },
    {
      id: "topBid",
      label: "Top Bid",
      valueRenderer: d =>
        <FractionTokenPrice
          className="justify-end"
          iconSize={12}
          price={Number(d.price.sellPrice)}
          showAsFullNFT={false} />,
      align: "right",
      sort: numberSort(a => a.price.sellPrice),
      hideOnMobile: true,
    },
    {
      id: "oneHourChange",
      label: "1h Change",
      valueRenderer: d =>
        <div className={classNames("flex flex-row items-center", {
          "text-call": d.stats.oneHourChange > 0,
          "text-neutral-content": d.stats.oneHourChange === 0,
          "text-put": d.stats.oneHourChange < 0,
        })}>
          {d.stats.oneHourChange > 0 ? <GoTriangleUp size={12} /> : <GoTriangleDown size={12} />}{ Math.abs(d.stats.oneHourChange).toFixed(2)}%
        </div>,
      align: "right",
      hideOnMobile: true,
      sort: numberSort(a => a.stats.oneHourChange)
    },
    {
      id: "oneDayChange",
      label: "1d Change",
      valueRenderer: d =>
        <div className={classNames("flex flex-row items-center", {
          "text-call": d.stats.oneDayChange > 0,
          "text-neutral-content": d.stats.oneDayChange === 0,
          "text-put": d.stats.oneDayChange < 0,
        })}>
          {d.stats.oneDayChange > 0 ? <GoTriangleUp size={12} /> : <GoTriangleDown size={12} />}{ Math.abs(d.stats.oneDayChange).toFixed(2)}%
        </div>,
      align: "right",
      sort: numberSort(a => a.stats.oneDayChange),
    },
    {
      id: "1hVolume",
      label: "1h Volume",
      valueRenderer: d =>
        <div className="flex flex-row items-center">
          <span className="text-base">$</span>{prettifyNumber(d.stats.oneHourVolumeUsd)}
        </div>,
      align: "right",
      hideOnMobile: true,
      sort: numberSort(a => a.stats.oneHourVolumeUsd)
    },
    {
      id: "1dVolume",
      label: "1d Volume",
      valueRenderer: d =>
        <div className="flex flex-row items-center">
          <span className="text-base">$</span>{prettifyNumber(d.stats.oneDayVolumeUsd)}
        </div>,
      align: "right",
      sort: numberSort(a => a.stats.oneDayVolumeUsd),
    }
  ];

  return (
    <Table
      id="fnft_collections"
      initialSort="1dVolume"
      initialSortDirection="desc"
      className="md:min-w-[800px] !bg-transparent"
      columns={columns}
      data={data}
      isLoading={isLoading}
      error={isError ? "Failed to load" : undefined}
      rowOnClick={d => onSelected(d.token)}
    />
  )
}
